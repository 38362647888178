.breadcrumb-wrapper {
  @apply  flex overflow-x-auto whitespace-nowrap pt-4;
}

.breadcrumb {
  @apply inline-flex items-center space-x-1 md:space-x-3;

  .breadcrumb-item {
    @apply ml-1 text-base font-medium text-gray-700 hover:text-gray-900 md:ml-2;

    &.active {
      @apply text-blue-800;
    }
  }
}
