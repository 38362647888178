@tailwind base;
@tailwind components;
@tailwind utilities;

@import './assets/scss/dashboard';
@import './assets/scss/navigation';
@import './assets/scss/form';
@import './assets/scss/button';
@import './assets/scss/breadcrumb';
@import './assets/scss/modal';
@import './assets/scss/monaco-editor';
