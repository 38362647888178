.dropdown-menu-container {
  @apply rounded bg-white py-1 text-sm text-gray-700 shadow;

  &.action-table {
    @apply flex flex-col gap-2 p-3 text-center;
  }

  .dropdown-menu {
    @apply block w-full py-2 px-4 text-left hover:bg-gray-100;
  }

  .devide {
    @apply my-1 block h-[1px] w-full bg-gray-100 px-2;
  }
}

.wrapper-dashboard {
  @apply flex flex-col gap-6 p-4 lg:p-6;

  .title {
    @apply text-3xl font-semibold text-gray-800 md:text-4xl;
  }

  .header-wrapper {
    @apply flex flex-wrap justify-between gap-4;

    .action-wrapper {
      @apply flex items-center gap-3;
    }
  }

  .content-wrapper {
    @apply flex flex-col flex-wrap items-center justify-center gap-6 lg:flex-nowrap xl:flex-row xl:items-start;

    .wrapper-form,
    form {
      @apply w-full space-y-4;
    }

    .title-wrapper {
      @apply mb-4 flex items-center justify-between border-b border-gray-200;

      .title {
        @apply py-4 text-2xl font-semibold text-gray-800;
      }
    }

    .form-container {
      @apply w-full rounded-md bg-white p-6;
    }

    .form-animate {
      @apply transition-all duration-300;
    }

    .form-wrapper {
      @apply space-y-6 py-4;
    }

    .info-content {
      @apply w-full max-w-[380px] rounded-md bg-white p-6;
    }

    .button-wrapper {
      @apply flex w-full items-center justify-end gap-4 rounded-md bg-white p-6;
    }
  }
}

.hamburger {
  @apply absolute right-0 bottom-0 flex h-10 w-10 items-center justify-center;

  > div {
    @apply relative inset-0 h-[3px] w-3/5 bg-gray-700 transition-all duration-300;

    &::before,
    &::after {
      content: '';

      @apply absolute left-0 -top-2 h-[3px] w-full bg-gray-700 transition-all duration-300;
    }

    &::after {
      @apply top-2;
    }
  }

  &.active {
    > div {
      @apply bg-transparent;

      &::before {
        @apply top-0 rotate-45;
      }

      &::after {
        @apply top-0 rotate-135;
      }
    }
  }
}

.loading-install {
  display: inline-block;
  clip-path: inset(0 3ch 0 0);
  animation: l 1s steps(4) infinite;
}

@keyframes l {
  to {
    clip-path: inset(0 -1ch 0 0);
  }
}

.react-tabs__tab-panel--selected {
  width: 100%;
}