.navigation {
  .menu {
    @apply flex w-full items-center border-transparent p-3 text-base font-normal text-gray-900 hover:text-blue-800;

    &.active {
      @apply bg-blue-400 text-blue-800 rounded-lg;
    }
  }

  .active-child {
    @apply bg-blue-50;

    .menu {
      @apply text-blue-800 hover:font-bold;
    }

    button.menu {
      @apply text-blue-800;
    }
  }

  button.menu {
    @apply justify-between;
  }

  button.collapse-menu{
    @apply justify-center;
  }

  ul ul .menu {
    @apply pl-12;
  }
}

::-webkit-scrollbar {
  width: 14px;
  height: 14px;
}

::-webkit-scrollbar-track {
  background: #d9d9d9;
  border-radius: 14px;
}

::-webkit-scrollbar-thumb {
  background: #a0a0a0;
  border-radius: 14px;
}

.scrollbar-track-rounded-full::-webkit-scrollbar-track {
  border-radius: 14px;
}

.scrollbar-thumb-rounded-full::-webkit-scrollbar-track {
  border-radius: 14px;
}

.scrollbar-thumb-blue-50::-webkit-scrollbar-track {
  background: #c4c4c4;
}

.scrollbar-track-gray-50::-webkit-scrollbar-thumb {
  background: #6638b6;
}

.mini-scrollbar {
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }

  &::-webkit-scrollbar-track {
    background: #d9d9d9;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background: #a0a0a0;
    border-radius: 8px;
  }

  &.scrollbar-track-rounded-full::-webkit-scrollbar-track {
    border-radius: 8px;
  }

  &.scrollbar-thumb-rounded-full::-webkit-scrollbar-track {
    border-radius: 8px;
  }

  &.scrollbar-thumb-blue-50::-webkit-scrollbar-track {
    background: #c4c4c4;
  }

  &.scrollbar-track-gray-50::-webkit-scrollbar-thumb {
    background: #6638b6;
  }
}
